<template>
  <div>
    <member-breadcrumb :title="title"/>

    <div class="info">
      <label>姓名：</label>
      <span>{{info.userName}}</span>
    </div>

    <div class="info">
      <label>注册手机：</label>
      <span>{{info.phone}}</span>
    </div>
    <div class="info">
      <label>注册时间：</label>
      <span>{{dateUtil.format(info.createdAt)}}</span>
    </div>
  </div>
</template>

<script>
  import dateUtil from 'tdw-date-util'
  import MemberBreadcrumb from '../common/MemberBreadcrumb'

  export default {
    name: 'MemberInfo',
    components: {MemberBreadcrumb},
    data () {
      return {
        title: '账号信息',
        info: {
          userName: '',
          vipFlag: '',
          vipLevel: '',
          expireTime: ''
        },
        timestamp: new Date().getTime(),
        dateUtil
      }
    },
    beforeCreate () {
      const self = this
      self.$http.get({
        url: '/member/info.do',
        success (res) {
          self.info = res.data
        }
      })
    }
  }
</script>

<style scoped>
  div.info {
    font-size: 0;
  }

  span, label {
    display: inline-block;
    line-height: 40px;
    font-size: 14px;
  }

  label {
    width: 100px;
    text-align: right;
    color: #666666;
  }

  div > span {
    color: #333333;
  }
</style>